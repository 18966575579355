import React, { useCallback, useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";

import { Parser } from "html-to-react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineMinus } from "react-icons/ai";
// import { currencyFormatter } from "../utils/currencyFormatter";
import { BsMessenger, BsPlus } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import { addToSingleCart } from "../features/products/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";

import { toast } from "react-toastify";
// import ProductCard from "../components/ProductCard";
import TagManager from "react-gtm-module";
import { singleProductFetching } from "../features/products/singleProductSlice";
import { thumbImageFetching } from "../features/products/thumbImageSlice";
import { Triangle } from "react-loader-spinner";

// components start
const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // const { items: data } = useSelector((state) => state.products);
  // const { items: size } = useSelector((state) => state.sizes);

  const { items: singleProduct, loader } = useSelector(
    (state) => state.singleProduct
  );

  const { items: thumbImage } = useSelector((state) => state.thumbImage);

  // console.log(singleProduct);

  // const [color, setColor] = useState([]);

  // const [size, setSize] = useState([]);
  // console.log(color);

  // const [colorAtr, setColorAtr] = useState("");

  // const [sizeAtr, setSizeAtr] = useState("");
  const [size, setSize] = useState([]);

  const [response, setResponse] = useState({});

  const [selectedOption, setSelectedOption] = useState(null);

  const [cartQuantity, setCartQuantity] = useState(1);
  const htmlParser = new Parser();
  const navigate = useNavigate();

  useEffect(() => {
    const SizeAttribute = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-specificattribute/${id}`
      );
      setSize(res.data);
      if (res.data.length > 0) {
        setSelectedOption(res.data[0].variant);
      }
    };
    SizeAttribute();
  }, [id]);

  useEffect(() => {
    if (selectedOption) {
      fetch(`${process.env.REACT_APP_URL}/api-findproductstock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productid: singleProduct?.product?.id,
          variantid: selectedOption,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data as needed
          console.log("Response from server:", data);
          setResponse(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedOption, singleProduct?.product?.id]);

  const handleRadioClick = (opstion) => {
    setSelectedOption(opstion);
    setCartQuantity(1);
  };

  if (response.discount_price > 0) {
  }
  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);
  const handleAddToCart = () => {
    if (!selectedOption) {
      toast.warn("Select your product size plse", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      // dispatch(removeAllFromCart());
      if (response.discount_price > 0) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.discount_price,
          })
        );
      }
      if (response.price > 0 && response.discount_price < 1) {
        dispatch(
          addToSingleCart({
            ...singleProduct.product,
            cartQuantity,
            sizeAtr: selectedOption,
            response: response?.price,
          })
        );
      }

      // navigation("/checkout");
      setCartQuantity(1);
    }

    const value =
      response.discount_price > 0 ? response?.discount_price : response?.price;
    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart", // Event name
        buttonName: "add_to_cart", // Additional data

        ecommerce: {
          currency: "BDT",

          value: value * cartQuantity,

          itmes: [
            {
              item_name: singleProduct.product.name,
              item_id: singleProduct.product.id,
              price:
                singleProduct.product.after_discount > 0
                  ? singleProduct.product.after_discount
                  : singleProduct.product.unit_price,
              sizewise_price:
                response.discount_price > 0
                  ? response?.discount_price
                  : response?.price,
              item_category: singleProduct.product.category_name,
              item_subcategory: singleProduct.product.subcategory_name,
              quantity: cartQuantity,
              size: selectedOption,
            },
          ],
        },
      },
    });
  };

  const goToCheckout = () => {
    navigate("/checkout");
  };

  const handleBuyNow = () => {
    handleAddToCart();
    goToCheckout();

    const value =
      response.discount_price > 0 ? response?.discount_price : response?.price;
    TagManager.dataLayer({
      dataLayer: {
        event: "begin_checkout", // Event name
        buttonName: "begin_checkout", // Additional data
        ecommerce: {
          currency: "BDT",

          value: value * cartQuantity,

          itmes: [
            {
              item_name: singleProduct.product.name,
              item_id: singleProduct.product.id,
              price:
                singleProduct.product.after_discount > 0
                  ? singleProduct.product.after_discount
                  : singleProduct.product.unit_price,
              sizewise_price:
                response.discount_price > 0
                  ? response?.discount_price
                  : response?.price,
              item_category: singleProduct.product.category_name,
              item_subcategory: singleProduct.product.subcategory_name,
              quantity: cartQuantity,
              size: selectedOption,
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    dispatch(singleProductFetching(id));
    dispatch(thumbImageFetching(id));
  }, [dispatch, id]);

  return (
    <>
      {!loader ? (
        <div className="wrapper">
          {/* grid grid-cols-2 2xl:grid-cols-9 gap-10 */}
          <div className="grid xl:grid-cols-[50%_50%] 2xl:grid-cols-[58%_42%] gap-10">
            {/* col-span-2  md:col-span-9 lg:col-span-3 */}
            <div className="left image flex w-full justify-center  mt-3 px-2 md:px-0">
              {/* <div>
          <img
            src={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
            alt="pic"
          />
        </div> */}
              <ProductImageCarasol
                productImg={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
                thumbImage={thumbImage}
              />
            </div>
            {/* flex flex-col gap-8 col-span-2  md:col-span-9  lg:col-span-6 */}
            <div className="right flex flex-col gap-5 lg:gap-5 2xl:gap-8  p-3">
              <div className="font-semibold mt-5 text-lg lg:text-3xl">
                {singleProduct?.product?.name}
              </div>
              <div className="flex items-center justify-start gap-4 ">
                <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
                  size
                </span>
                <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
                  {size?.map((s, index) => (
                    <button
                      key={index}
                      onClick={() => handleRadioClick(s.variant)}
                      className={`border border-red-500 py-3 px-4 ${
                        selectedOption === s.variant
                          ? "bg-red-600 text-white"
                          : ""
                      }`}
                    >
                      {s.variant}
                    </button>
                  ))}
                </div>
              </div>

              <div className="font-medium flex items-center gap-4 text-3xl">
                <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
                  price
                </span>
                {response.price > 0 ? (
                  <>
                    <div className="flex gap-5">
                      {response.discount_price > 0 ? (
                        <div className="flex gap-5">
                          <span className="font-normal line-through text-black decoration-200">
                            ৳ {+response?.price}
                          </span>
                          <span className="text-red-600 font-normal">
                            ৳ {+response.discount_price * cartQuantity}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <div className="text-red-600 font-normal">
                            ৳ {+response?.price * cartQuantity}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="text-red-600 font-light text-xl">
                    <span>select your product size</span>
                  </div>
                )}
              </div>

              <div className="flex items-center gap-3">
                <span className="inline-block text-slate-600/50 text-[1rem] font-medium  uppercase items-center">
                  Quantity
                </span>
                <div className="flex items-center border border-gray-300 gap-4 ">
                  <button
                    className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50 flex items-center justify-center"
                    onClick={() => handleDecrease(singleProduct?.product)}
                  >
                    <AiOutlineMinus />
                  </button>
                  <span>{cartQuantity}</span>
                  <button
                    className="h-10 w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50 flex items-center justify-center"
                    onClick={() => handleIncrease(singleProduct?.product)}
                  >
                    <BsPlus />
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-2 text-white">
                <div className="flex gap-3 w-full col-span-2">
                  <button
                    // onClick={handleBuyNow}
                    onClick={handleBuyNow}
                    disabled={!response.price}
                    className={`${
                      !selectedOption || !response.price
                        ? "bg-red-600 cursor-not-allowed"
                        : "bg-red-600 hover:bg-[#3A3A3A]"
                    }  py-3 px-5 w-full rounded-md col-span-2  text-white duration-300`}
                  >
                    {/* "w-full py-3 px-5 bg-[#C3161C] hover:bg-gray-700 flex items-center
            justify-center" */}
                    <span className="text-base md:text-2xl">
                      {/* onClick={goToCheckout} */}
                      অর্ডার করুন
                    </span>
                  </button>
                  <button
                    onClick={handleAddToCart}
                    disabled={!response.price}
                    className={`${
                      !selectedOption || !response.price
                        ? "bg-red-600 cursor-not-allowed"
                        : "bg-red-600 hover:bg-[#3A3A3A]"
                    }  py-3 px-5 w-full rounded-md col-span-2  text-white duration-300`}
                  >
                    {/* "w-full py-3 px-5 bg-[#C3161C] hover:bg-gray-700 flex items-center
            justify-center" */}
                    <span className="text-base md:text-2xl">Add To Cart</span>
                  </button>
                </div>
                {/* <button
            onClick={handleAddToCart}
            className="w-full bg-[#FA5303] py-3 px-5 flex items-center justify-center"
          >
            Add to Cart
          </button> */}

                <Link
                  className="w-full py-2 px-5 bg-blue-500 hover:bg-[#ED423E] duration-300 col-span-2 flex flex-col items-center justify-center"
                  to={`https://wa.me/+8801878206101`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>অর্ডার অথবা কল করতে ক্লিক করুন</span>
                  <span>(+8801878206101)</span>
                </Link>

                <Link
                  to={`https://m.me/sombhaar`}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full py-2 px-5 bg-blue-500 hover:bg-[#ED423E] duration-300 col-span-2 flex flex-col items-center justify-center"
                >
                  <span>বিস্তারিত জানতে মেসেজ করুন</span>
                  <span className="text-xl">
                    <BsMessenger />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="details p-3">
            <SectionTitle title={"Discription"} />

            <div className="description w-[100%]  overflow-x-scroll">
              {singleProduct?.product?.description ? (
                <span className="">
                  {htmlParser.parse(singleProduct?.product?.description)}
                </span>
              ) : (
                <div className="text-2xl text-red-500 flex items-center justify-center">
                  <span>No Description</span>
                </div>
              )}
            </div>
          </div>
          <div className="mb-10">
            <RelatedProduct singleProduct={singleProduct} />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-[calc(100vh-20rem)]">
          <Triangle height="80" width="80" color="#C3161C" />
        </div>
      )}
    </>
  );
};

export const ProductImageCarasol = ({ thumbImage, productImg }) => {
  const [mainImage, setMainImage] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [selectedThumbIndex, setSelectedThumbIndex] = useState(0);
  const thumbnailContainerRef = useRef(null);

  useEffect(() => {
    if (productImg) {
      setMainImage(productImg);
    }
  }, [productImg]);

  const handleMouseEnter = () => {
    setZoomLevel(2);
  };

  const handleMouseLeave = () => {
    setZoomLevel(1);
    setZoomPosition({ x: 0, y: 0 });
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  const handleThumbnailClick = (index) => {
    setSelectedThumbIndex(index);
    setMainImage(
      `${process.env.REACT_APP_URL}/uploads/product/${thumbImage[index]?.icon}`
    );
  };

  const scrollThumbnails = (direction) => {
    const container = thumbnailContainerRef.current;
    if (direction === "left") {
      container.scrollBy({ left: -100, behavior: "smooth" });
    } else if (direction === "right") {
      container.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  return (
    <div className="relative text-white text-[20px] w-full object-cover">
      <div
        className="w-full h-[25rem] md:h-[30rem] lg:h-[33rem] 2xl:h-[38rem]  overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {mainImage && (
          <img
            src={mainImage}
            alt="Product"
            className="object-fill w-full h-full overflow-hidden cursor-pointer"
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
            }}
          />
        )}
      </div>
      <div className="flex mt-4 space-x-2 items-center">
        {thumbImage.length > 4 && (
          <button
            className="bg-gray-300 text-black p-2 rounded-full"
            onClick={() => scrollThumbnails("left")}
          >
            {"<"}
          </button>
        )}
        <div
          ref={thumbnailContainerRef}
          className="flex overflow-x-scroll space-x-2 w-[calc(100%-4rem)] scrollbar-hide"
        >
          {thumbImage.map((image, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_URL}/uploads/product/${image?.icon}`}
              alt={`Thumbnail ${index}`}
              className={`w-[85px] h-[85px] object-cover cursor-pointer ${
                selectedThumbIndex === index ? "border-2 border-blue-500" : ""
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
        {thumbImage.length > 4 && (
          <button
            className="bg-gray-300 text-black p-2 rounded-full"
            onClick={() => scrollThumbnails("right")}
          >
            {">"}
          </button>
        )}
      </div>
    </div>
  );
};
export default ProductDetails;
